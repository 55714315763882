<template>
  <div class="position-absolute p-header">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-end theme-header">
          <router-link
            :to="{
              name:  this.componentName,
              params: {
                assetType: this.subheading
              }
            }"
            class="btn-back">
          </router-link>
          <p class="m-auto">{{ this.displayHeading }}</p>
          <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
            <img src="/img/ui-theme/contact-icon-rev.svg" alt="Fullscreen">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from '../global'
import { unslugify } from "unslugify";

export default {
  name: 'ViewTopNav',
  props: [
    'heading',
    'subheading'
  ],
  data() {
    return {
      componentName: "DashboardHome"
    }
  },
  setup() {
    const {theme} = global
    let displayHeading = ""

    return {
      theme, displayHeading
    }
  },
  created() {
    this.displayHeading = unslugify(this.heading)
  }
};
</script>