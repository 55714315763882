<template>
  <DashboardVideo />
  <div class="container d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
      <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end theme-header">
        <router-link
          :to="{
            name: componentAdmin
          }"
          class="btn-back opacity-0"
        >
        </router-link>
        <a href="#" class="m-auto"><img :src="`/img/ui-theme/` + theme.devLogo" /></a>
        <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
          <img src="/img/ui-theme/contact-icon-rev.svg" alt="Fullscreen">
        </button>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-center z-2">
        <div class="row g-0">
          <div class="col-12" v-if="collection">
            <template v-for="asset in assetTypes" :key="asset.Guid">
              <!-- Video links -->
              <router-link
                v-if="asset.LinkName === 'videos'"
                :to="{
                  name:  asset.ComponentName,
                  params: {
                    assetType: this.assetType,
                    viewType: asset.LinkName,
                    lid: collection.videoLibraryId,
                    collectionId: collection.guid
                  }
                }"
                class="btn-theme-menu mb-2"
              >
                <img :src="theme.videosIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
              </router-link>
              
              <!-- Panorama links -->
              <router-link
                v-if="asset.LinkName === 'panoramas' || asset.LinkName === 'virtual-tour' || asset.LinkName === 'balcony-views'"
                :to="{
                  name:  asset.ComponentName,
                  params: {
                    assetType: this.assetType,
                    viewType: asset.LinkName
                  }
                }"
                class="btn-theme-menu mb-2"
              >
                <img :src="theme.panoramasIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
              </router-link>
              
              <!-- Renders links -->
              <router-link
                v-if="asset.LinkName === 'renders' || asset.LinkName === 'range'"
                :to="{
                  name:  asset.ComponentName,
                  params: {
                    assetType: this.assetType,
                    viewType: asset.LinkName
                  }
                }"
                class="btn-theme-menu mb-2"
              >
                <img :src="theme.rendersIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
              </router-link>
              
              <!-- Floorplans links -->
              <router-link
                v-if="asset.LinkName === 'floorplans'"
                :to="{
                  name:  asset.ComponentName,
                  params: {
                    assetType: this.assetType,
                    viewType: asset.LinkName
                  }
                }"
                class="btn-theme-menu mb-2"
              >
                <img :src="theme.floorplansIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
              </router-link>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex align-items-end justify-content-end sticky-bottom theme-footer">
        <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo"/>
      </div>
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import DashboardVideo from "@/components/DashboardVideo.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "DashboardHome",
  components: {
    DashboardVideo,
    OffCanvas
  },
  data() {
    return {
        assetType: 'apartment-type-2c',
        assetTypes: null,
        collection: null,
        componentName: "AssetView",
        componentAdmin: "Admin",
        folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/apartment-type-2c/'),
        brochureUrl: "https://florian.metaluxe.com.au/brochure/MCO0024_Master%20brochure%2048pp_320x240_V9%20(2).pdf"
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
    .then(response => {
        console.log(response)
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          ComponentName: this.componentName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName
        };
      });

      // Assign type menu array
      if (this.assetType === 'apartment-type-2c') {
        this.assetOrder = this.theme.apartmenttype2cMenuOrder
      }

      // Sort array
      const customMenu = sortArray(menuItems, {
        by: ['LinkName'],
        order: 'LinkName',
        customOrders: {
          LinkName: this.assetOrder
        }
      })
      
      this.assetTypes = customMenu
    })
    .catch(error => {
      console.log(error)
    })
    // api call get video collection details
    BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
    .then(response => {
        console.log(response)
      
      this.collection = response.data[0]
    })
    .catch(error => {
      console.log(error)
    })
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
};
</script>
