import { reactive } from "vue";

const theme = reactive({
  agentLogo: "coco-republic-footer-logo.svg",
  devLogo: "coco-republic-header-logo.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "2e6212cb-a9f9-477d-87b2-837571a6f40d",
  videoLibraryId: "45029",
  streamCdnUrl: "https://vz-43389239-d55.b-cdn.net",
  streamApiKey: "7c7111c6-4f8c-4828-a5e0bb5041d8-f3b5-4156",
  storageApiPassword: "7dba3b2a-5a83-49eb-ae5c389516f1-a9b5-4d1c",
  cdnUrl: "https://cocorepublic.b-cdn.net",
  devFolder: "/cocorepublic",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  
  // top level menu
  assetsMenuOrder: [
    'apartment-type-2c',
  ],
  
  apartmenttype2cMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range'
  ],
  
  apartmenttype2cAsset: {
    videos: [],
    videosName: [
    ],
    panoramas: [ 0 ],
    panoramasName: [
      'Apartment Type 2c'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/phxJHdGilOsi?mls=1'
    ],
    range: [  ],
    rangeName: [

    ],
    rangeUrl: [
    ]
  },
});

export default { theme };